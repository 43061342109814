<template>
  <main class="d-flex flex-column align-items-center justify-content-center">
    <b-container class="text-center">
      <b-row>
        <b-col sm="12">
          <h1 class="error-status text-primary font-weight-bold d-block">
            {{ errorStatus }}
          </h1>
          <h2 class="text-primary display-2 font-weight-bold">Oops!</h2>
          <p class="m-fs-18">
            {{ message }}
          </p>
          <b-button variant="outline-primary" class="min-w-200 font-weight-bold" to="/">
            Home
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      errorStatus: (state) => state.errorStatus,
    }),
    message() {
      return this.errorStatus === 404
        ? "Sorry, we can't find this page! Don't worry, everything is still awesome."
        : "Something went wrong! Try again or get on with other things while we try to figure it out!";
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  min-height: 100vh;
}
.error-status {
  font-size: 16rem;
  text-shadow: 4px 4px $grey-tint-5;
}
</style>
